import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'

Vue.use(VueRouter)

export const constantRouterMap = [
  {
    path: '/',
    redirect:'/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login'),
  },
  {
    path: '/loginFromQr/:uuid',
    name: 'loginFromQr',
    component: () => import('@/views/login/loginFromQr')
  },
  {
    path: '/resetPass',//上课
    name: 'resetPass',
    component: () => import('@/views/forget/forget'),
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
      {//首页
        path: 'series',
        component: () => import('@/views/series/series'),
      },
      {
        path: '/home/series/detail/:id',//详情页
        name: 'details',
        props:true,
        component: () => import('@/views/details/details'),
      },
      {
        path: '/details/list/:id',//列表
        name: 'list',
        props:true,
        component: () => import('@/views/details/list/detailList'),
      },
      {
        path: '/details/list/info/:id',//上课
        name: 'info',
        props:true,
        component: () => import('@/views/details/info/detailInfo'),
      }
    ],
    redirect: {
      path: '/home/series',
      component: () => import('@/views/series/series'),
    }
  }
]

const router = new VueRouter({
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRouterMap
})

export default router
