<!--  -->
<template>
<!-- 头部 --> 
    <div class="common-header">
       <!-- 个人中心 -->
      <el-dropdown trigger="click" class="mySelf" style="float: right;" @command="handleCommand">
        <div class="el-dropdown-link" style="color:#fff;width:2.7125rem;height:1.175rem;line-height:1.175rem">
          {{personalCenter.name}}<i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown" style="width:2.7125rem;">
          <!-- <el-dropdown-item command="resetPass">
            重置密码
          </el-dropdown-item> -->
          <el-dropdown-item command="exit">
            <div style="display:block;">退出</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div style="height:1px;width:1.7rem;float: right;"></div>
      <!-- nav -->
      <el-menu
      :default-active="''+activeIndex"
      mode="horizontal"
      active-text-color="#5B87FFFF"
      class="navBox"
      style="float: right;"
    >
      <el-menu-item
        v-for="(item,index) in navData"
        :index="''+index"
        :key="item.id"
        @click="changeNav(item.id)"
      >{{item.name}}</el-menu-item>
      </el-menu>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import apis from '../../api/request'
export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
  //这里存放数据
return {
  school_id:"",
    jumpPath:"",
    Num:1,
    personalCenter:{
        name:"admin",
    },//个人中心
    activeIndex:0,
    navData:[],
};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
  changeNav(index){//传递的是id
    this.Num = index
    localStorage.setItem("categoryId", index)
    this.$emit("handleClick", this.Num)
    // console.log(this.Num);
  },
  handleCommand(command){
    // console.log(command);
    this.jumpPath = command
    this.$emit("jump", this.jumpPath)
  }
},
beforeCreate() {}, //生命周期 - 创建之前
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
beforeMount() {}, //生命周期 - 挂载之前
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
  // console.log(localStorage.getItem('userName'));
  let userName = localStorage.getItem('userName')
  if(userName){
    this.personalCenter.name = userName
  }
  this.school_id = localStorage.getItem("schoolId")
  // console.log("9090909", this.school_id);
  this.$get(apis.courseCategories+this.school_id).then(res=>{
    // console.log(res);
    if(res.code === 0){
      this.navData = res.data
      if(res.data == ""){
        this.$message.error("您没有授权系列或系列获取失败，请查看是否授权，如若已授权请联系开发人员")
        return
      }
      localStorage.setItem("categoryId", this.navData[0].id)
      this.changeNav(this.navData[0].id)
      // console.log();
    }
  })
},
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
    .mySelf{
        width:2.7125rem;
        height: 1.175rem;
        background-color:#5B87FF;
        /* display: flex; */
        /* align-items:center; */
        /* justify-content:center; */
        cursor: pointer;
    }
    .navBox{
        height:1.175rem;
        /* color:#43412DFF!important; */
    }
    .el-menu.el-menu--horizontal{
        border-bottom:0px;
    }
    .el-menu--horizontal>.el-menu-item{
        height:1.175rem;
        line-height: 1.175rem;
        /* padding:20px; */
        /* margin:auto; */
        /* display: flex; */
        /* align-items:center; */
        /* justify-content:center;  */
    }
    .el-menu--horizontal > .el-menu-item.is-active{
      color:#409EFF;
      border-bottom:2px solid #409EFF!important;
    }
    .el-menu--horizontal :not(.is-disabled).el-menu-item:focus, .el-menu--horizontal :not(.is-disabled).el-menu-item:hover{
      color:#409EFF;
    }
</style>