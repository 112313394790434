export default {
    // headers: {
    //   'Content-Type': 'application/json; charset=UTF-8',
    //   'Authorization': null
    // },
    timeout: 50000,
  
    emulateJSON: true,
  
    withCredentials: false, // default
  
    responseType: 'json', // default
  
    maxContentLength: 2000,
  
    validateStatus: function (status) {
      return status >= 200 && status < 300; // default
    },
  
    maxRedirects: 5, // default
  }