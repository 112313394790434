import axios from 'axios';
import { Message } from 'element-ui';
import store from '../store/index';
import config from "./config";
import router from '../router';
// import apis from "./request";
// import {fileDownload} from "../utils";
// import _ from 'lodash'
// 添加请求拦截器
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
  // console.log(1111111111111);
  // console.log('13', token);
  if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
    config.headers.common['Authorization'] = token  //请求头加上token
  }
  // console.log("16", config);
  return config;
}, (error) => {
  Message.error('出错了')
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(    
  response => {        
    // const token = localStorage.getItem('token')
    // console.log(token);
    // if (!token || token == "" || token == null) {
    //   console.log(111111);
    //   this.$router.push("/login")
    //   return
    // }
    if (response.status === 200) {            
      return Promise.resolve(response);        
    } else {            
      return Promise.reject(response);        
    }    
  },
  // 服务器状态码不是200的情况    
  error => {        
    if (error.response.status) {            
      switch (error.response.status) {                
      // 401: 未登录                
      // 未登录则跳转登录页面，并携带当前页面的路径                
      // 在登录成功后返回当前页面，这一步需要在登录页操作。                
        case 401:                    
          router.replace({                        
          path: '/login',                        
          query: { redirect: router.currentRoute.fullPath } 
        });
        break;
      // 403 token过期                
      // 登录过期对用户进行提示                
      // 清除本地token和清空vuex中token对象                
      // 跳转登录页面                
        case 403:                     
          Message({                        
            message: '登录过期，请重新登录',                        
            duration: 1000,                        
            forbidClick: true                    
          });                    
          // 清除token                    
          localStorage.removeItem('token');                    
          store.commit('loginSuccess', null);                    
          // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          setTimeout(() => {                        
            router.replace({                            
              path: '/login',                            
              query: { 
                redirect: router.currentRoute.fullPath 
              }                        
            });                    
          }, 1000);                    
          break; 
      // 404请求不存在                
        case 404:                    
          Message({                        
            message: '网络请求不存在',                        
            duration: 1500,                        
            forbidClick: true                    
          });                    
          break;                
      // 其他错误，直接抛出错误提示                
        default:                    
          Message({                        
            message: error.response.data.message,                        
            duration: 1500,                        
            forbidClick: true                    
          });            
      }            
      return Promise.reject(error.response);        
    }       
  }
);
export async function get(url, params, config) {
  try {
    let res = await axios.get(url, {
      params: params || {},
      ...config || {}
    });
    return new Promise((resolve, reject) => {
      if(res){
        resolve(res.data)
      } else {
        reject()
      }

    })
  } catch (err) {
    console.log(err)
    return Promise.reject(err);
  }
}

// export function download(url, params, fileName, config) {
//   let result = {}
//   return get(url, params, Object.assign({responseType: "arraybuffer"}, config || {})).then(
//     response=>{
//       var blob = new Blob([response]);
//       //将 Blob对象 读成字符串
//       var reader = new FileReader();
//       reader.readAsText(blob, 'utf-8');
//       reader.onload = function (e) {
//         result= reader.result
//         try {
//           Message.error(JSON.parse(result).message)
//         } catch (error) {
//           fileDownload(response, fileName);
//         }
//       }
//     }).catch(
//     );
// }

export async function post(url, data) {
  try {
    let res = await axios.post(url, data || {}, {})
    return new Promise((resolve, reject) => {
      resolve(res.data)
      reject()
    })
  } catch (err) {
    return Promise.reject(err);
  }
}


export async function destroy(url, params) {
  try {
    let res = await axios.delete(url, {
      params: params || {}
    })
    return new Promise((resolve, reject) => {
      resolve(res.data)
      reject()
    })
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function put(url, params) {
  try {
    let res = await axios.put(url, {
      params: params || {}
    }, config)
    return new Promise((resolve, reject) => {
      resolve(res.data)
      reject()
    })
  } catch (err) {
    return Promise.reject(err);
  }
}