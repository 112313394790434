//事件总线$EventBus
//getCourseId 获取分类id
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
//element相关的
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI, {
  size: 'mini',
});
Vue.use(Viewer, {
  defaultOptions: {
      zIndex: 9999
  }
})
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
Vue.use(VueVideoPlayer)
import VueAMap from 'vue-amap';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '000508894342977338627324e2eacf6d', // 自己到官网申请，我随便写的
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor','AMap.Geolocation','Geocoder'],//plugin所要用到的模块功能，按需添加
  v: '1.4.4',//高德 sdk 版本为 1.4.4
});
import axios from 'axios'
Vue.prototype.$axios = axios;

import {
  get,
  post,
  destroy,
  put,
} from "./api/http";
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$delete = destroy;
Vue.prototype.$put = put;
///   
// 全局导入css
import '@/styles/index.scss'
import '@/flexible/flexible.js'
import "babel-polyfill"
Vue.config.productionTip = false
Vue.prototype.$EventBus = new Vue()
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
