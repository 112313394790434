import './http.js'
//接口(front/api/v1)
// console.log(localStorage.getItem('schoolId'));
// const schoolId = localStorage.getItem('schoolId')
export default {
    getLogin: `front/api/v1/login`,//登录
    courseCategories: `front/api/v1/course/categories/`,//课程分类
    getCourse: `front/api/v1/courses`,//课程
    courseDeail: `front/api/v1/course/`,//详情
    courseSection: `front/api/v1/course/chapter/`,//章节
    videoList: `front/api/v1/videos`,//视频列表
    videoInfo: `front/api/v1/video/`,//视频详情
    playAuth: `front/api/v1/video/play/auth`,//播放凭证
    videoTool: `front/api/v1/course/attaches/list`,
    courseTabs: `front/api/v1/simple/courses`,
    getmaterial: `front/api/v1/course/material/`,
    getmaterialCategory: `front/api/v1/course/material/category/`,
    qrcode: `front/api/v1/qrcode`,
    checkQrLoginState: 'front/api/v1/checkQrLoginState'
}

