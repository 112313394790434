<!-- 第一页 -->
<template>
<div class="app-box outBox">
  <!-- 外层容器 -->
  <div style="width:100%;height:100%">
    <!-- 头部的装饰 -->
    <div class="topDecoration">
      <img :src="topDecoration" alt="" style="width:100%;height:100%">
    </div>
    <!-- 底部的装饰 -->
    <div class="bottomDecoration">
      <img :src="bottomDecoration" alt="" style="width:100%;height:100%">
    </div>
    <!-- 右边的装饰 -->
    <div class="rightDecoration" @click="goBack">
      <img :src="rightDecoration" alt="" style="width:100%;height:100%">
      <!-- <div>{{a}}</div> -->
    </div>
     <!-- logo -->
      <div class="logoBox1" @click="gohome">
        <img :src="pic" alt="" style="width:100%;height:100%;">
      </div>
    <!-- 头部 -->
      <commonHeader @handleClick="handleClick" @jump="jump"></commonHeader>
    <!-- 内容 -->
    <div class="main">
      <router-view :info="info" :total="total" :contentLoading="contentLoading" />
      <!-- <div v-show="this.num === 4">敬请期待</div> -->
    </div>
  </div>
</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
  import apis from '../../api/request'
  import commonHeader from '../components/header'
  // import series from '../series/series'
  import piv from '../../../static/images/logo2.png'
  import pho from '../../../static/images/middleschool.png'
  import bottomPiv from '../../../static/images/bottom.png'
  import topPiv from '../../../static/images/top.png'
  import rightPiv from '../../../static/images/right.png'
export default {
//import引入的组件需要注入到对象中才能使用
components: {
  commonHeader
},
data() {
//这里存放数据
return {
  // a:"",
  schoolId:"",
  gohome_age:"",
  schoolType:"",//动态logo
  school_type:"",
  age_section:"",
  total:0,
  page:1,
  info:{},
  category_id:"",
  contentLoading:false,//传给孩子的开关
  listData:"",
  pic:piv,
  rightDecoration:rightPiv,
  topDecoration:topPiv,
  bottomDecoration:bottomPiv,
  // num:null,
};
},
//监听属性 类似于data概念
computed: {
  
},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
  gohome(){
    if(this.$router.history.current.fullPath !== "/home/series"){
      this.category_id = localStorage.getItem("categoryId")
      this.gohome_age = localStorage.getItem("ageSection")
      this.$router.push("/home/series")
      if(this.gohome_age&&this.gohome_age!=""){
        let args = {
          category_id:this.category_id,
          page: this.page,
          age_section: this.gohome_age,
          limit: 4,
          school_id:this.schoolId
        }
        this.contentLoading = true
        this.$get(apis.getCourse, args).then(res=>{
          // this.$EventBus.$emit("loading", true)
          if(res.code === 0){
            // console.log(res.data.data);
            this.info = res.data.list
            this.total = res.data.total
            this.contentLoading = false
            localStorage.setItem("couseInfo", JSON.stringify(this.info))
            localStorage.setItem("courseTotal", this.total)
          }
          this.contentLoading = false
        }).catch(err=>{
          console.log(err);
          this.contentLoading = false
        })
      }else{
        if(this.schoolType&&this.schoolType == 1){
          let args = {
            category_id:this.category_id,
            page: this.page,
            age_section: 3,
            limit: 4,
            school_id:this.schoolId
          }
          this.contentLoading = true
          this.$get(apis.getCourse, args).then(res=>{
            // this.$EventBus.$emit("loading", true)
            if(res.code === 0){
              // console.log(res.data.data);
              this.info = res.data.list
              this.total = res.data.total
              this.contentLoading = false
              localStorage.setItem("couseInfo", JSON.stringify(this.info))
              localStorage.setItem("courseTotal", this.total)
            }
            this.contentLoading = false
          }).catch(err=>{
            console.log(err);
            this.contentLoading = false
          })
        }
        if(this.schoolType&&this.schoolType == 2){
          let args = {
            category_id:this.category_id,
            page: this.page,
            age_section: 1,
            limit: 4,
            school_id:this.schoolId
          }
          this.contentLoading = true
          this.$get(apis.getCourse, args).then(res=>{
            // this.$EventBus.$emit("loading", true)
            if(res.code === 0){
              // console.log(res.data.data);
              this.info = res.data.list
              this.total = res.data.total
              this.contentLoading = false
              localStorage.setItem("couseInfo", JSON.stringify(this.info))
              localStorage.setItem("courseTotal", this.total)
            }
            this.contentLoading = false
          }).catch(err=>{
            console.log(err);
            this.contentLoading = false
          })
        }
      }
    }
  },
  goBack(){//返回
    if(this.$router.history.current.fullPath&&this.$router.history.current.fullPath !== "/home/series"){
      // 
      // console.log('166', this.$router.history.current.path);
      // this.a =this.$router.history.current.fullPath
      // details/list/info 详情
      // home/series/detail 列表
      // this.$message.success(this.$router.history.current.fullPath)
      if(this.$router.history.current.fullPath&&this.$router.history.current.fullPath.indexOf("/home/series/detail")!= -1){
        this.category_id = localStorage.getItem("categoryId")
        if(this.category_id&&this.category_id!= ""){
          this.handleClick(this.category_id)
        }
      }else if(this.$router.history.current.fullPath&&this.$router.history.current.fullPath.indexOf("/details/list/info")!= -1){
        this.$router.go(-1)
      }else{
        this.$router.go(-1)
      }
    }else{
      this.$message.error("当前是首页哦")
    }
  },
  handleClick(count){
    // localStorage.setItem("categoryId",count)
    // console.log(count);
    if(this.$router.history.current.fullPath !== "/home/series"){
      this.$router.push("/home/series")
    }
    // this.age_section = localStorage.getItem("ageSection")
    localStorage.setItem("categoryId",count)
    this.school_type = localStorage.getItem("schoolType")
    // console.log("9090", this.school_type);
    if(this.school_type&&this.school_type == 1){
      this.contentLoading = true
      var age = localStorage.getItem("ageSection")
      if(age&&age!=""){
        let args = {
          category_id:count,
          page: this.page,
          age_section: age,
          limit: 4,
          school_id:this.schoolId
        }
        this.$get(apis.getCourse, args).then(res=>{
        // this.$EventBus.$emit("loading", true)
          if(res.code === 0){
            // console.log(res.data.data);
            this.info = res.data.list
            this.total = res.data.total
            this.contentLoading = false
            localStorage.setItem("couseInfo", JSON.stringify(this.info))
            localStorage.setItem("courseTotal", this.total)
          }
          this.contentLoading = false
        }).catch(err=>{
          console.log(err);
          this.contentLoading = false
        })
        // localStorage.setItem("classId", this.age_section)
        return
      }else{
        let args = {
          category_id:count,
          page: this.page,
          age_section: 3,
          limit: 4,
          school_id:this.schoolId
        }
        this.$get(apis.getCourse, args).then(res=>{
          // this.$EventBus.$emit("loading", true)
          if(res.code === 0){
            // console.log(res.data.data);
            this.info = res.data.list
            this.total = res.data.total
            this.contentLoading = false
            localStorage.setItem("couseInfo", JSON.stringify(this.info))
            localStorage.setItem("courseTotal", this.total)
          }
          this.contentLoading = false
        }).catch(err=>{
          console.log(err);
          this.contentLoading = false
        })
        // localStorage.setItem("classId", this.age_section)
        return
      }
    }
    if(this.school_type&&this.school_type == 2){
      this.contentLoading = true
      var ages = localStorage.getItem("ageSection")
      if(ages&&ages!=""){
        let args = {
          category_id:count,
          page: this.page,
          age_section: ages,
          limit: 4,
          school_id:this.schoolId
        }
        this.$get(apis.getCourse, args).then(res=>{
          if(res.code === 0){
            this.info = res.data.list
            this.total = res.data.total
            this.contentLoading = false
            localStorage.setItem("couseInfo", JSON.stringify(this.info))
            localStorage.setItem("courseTotal", this.total)
          }
          this.contentLoading = false
        }).catch(err=>{
          console.log(err);
          this.contentLoading = false
        })
      }else{
        let args = {
          category_id:count,
          page: this.page,
          age_section: 1,
          limit: 4,
          school_id:this.schoolId
        }
        this.$get(apis.getCourse, args).then(res=>{
          if(res.code === 0){
            this.info = res.data.list
            this.total = res.data.total
            this.contentLoading = false
            localStorage.setItem("couseInfo", JSON.stringify(this.info))
            localStorage.setItem("courseTotal", this.total)
          }
          this.contentLoading = false
        }).catch(err=>{
          console.log(err);
          this.contentLoading = false
        })
      }
    }
    // console.log(args);
  },
  jump(path){
    // console.log(path);
    // if(path&&path==="resetPass"){
    //   this.$router.push("/resetPass")
    // }
    if(path&&path==="exit"){
      this.$axios.defaults.headers['access-token'] = '';
      window.localStorage.clear()
      this.$router.push("/login")

    }
  }
},
beforeCreate() {}, //生命周期 - 创建之前
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
beforeMount() {}, //生命周期 - 挂载之前
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
  // localStorage.getItem("schoolType")
  // this.age_section = localStorage.getItem("ageSection")
  // console.log('158',this.age_section);
  this.schoolId = localStorage.getItem("schoolId")
  this.schoolType = localStorage.getItem("schoolType")
  if(localStorage.getItem("schoolType") == 2){
    this.pic = pho
  }
  // console.log(document.querySelectorAll(".el-menu-item")[0].classList);
  // this.handleClick(1)
  // this.category_id = localStorage.getItem("categoryId") 
},
beforeUpdate() {}, //生命周期 - 更新之前
updated() {
}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.outBox{
  background: url(../../../static/images/bgc.png) no-repeat;
  background-size: cover;
}
.main{
  padding:0px;
  overflow: hidden;
  position: relative;
  width:22.45rem;
}

.logoBox1{
  height:1.7rem;
  width:1.7rem;
  /* background-color:#fff; */
  position: absolute;
  top:0.1875rem;
  left:0.5rem;
  cursor: pointer;
}
.bottomDecoration{
  width: 1.6625rem;
  height:1.7625rem;
  /* background-color: #fff; */
  position: absolute;
  bottom:0;
  left:0;
}
.topDecoration{
  width: 6.6125rem;
  height:2.8595rem;
  /* background-color: #fff; */
  position: absolute;
  top:0;
  left:0;
  margin-left:4.8625rem;
  /* background-color: yellow; */
}
.rightDecoration{
  width:0.85rem;
  height: 0.85rem;
  position: absolute;
  bottom:0.65rem;
  right:0.7rem;
  cursor: pointer;
  /* background-color: #fff; */
}
.el-menu--horizontal > .is-active.el-menu-item{
  columns: #409eff;
}
.outBox.is-active{
  color:#409eff
}
/* .noThings{
  position: absolute;
} */
</style>